import styled from 'styled-components';
export default function Styles() {
  return(
      <>
      </>
  )
}
// import RobotoRegular from '/public/assets/fonts/Roboto-Regular.ttf';

// export const fontFaces = styled.h1`
//   @font-face {
//     font-family: 'RobotoRegular';
//     src: url(${RobotoRegular}) format('ttf');
//     font-weight: 400;
//     font-style: normal;
//   }
// `;



export const Container = styled.div`
  width: 80vw;
  min-height: 100vh;
  margin: 0 auto;
  padding: 4em 0 0 0;
  margin-right: 5%;
  @media(max-width: 900px) {
    width:90vw;
  }
`;

// export const MenuLateral = styled.div`
//     float: left;
//     width: 10vw;
//     height: 100vh;
//     padding: 7% 1em 0 1em;
//     border-right: solid 1px #01a0c6;
//     z-index: 0;
//     img {
//         display: table;
//         margin: 0 auto;
//         width: 50px;
//         cursor: pointer;
//     }
//     ul {
//         display: table;
//         list-style: none;
//         margin: 6em auto 0 auto;
//         padding: 0;
//     }
//     li {
//         margin: .2em 0;
//     }
//     a {
//         text-decoration: none;
//         color: #4d4d4d;
//         transition: all .8s;
//         &:hover {
//             color: #01a0c6;
//         }
//     }
// `;