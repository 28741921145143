import AsideMenu3 from "./asideMenu3";
import styled from 'styled-components';
import { Container } from './styles.js';
import { motion } from 'framer-motion';

const WhatWeDo01 = styled.div`
    background: #01a0c6;
`;
const ContentPage03 = styled.div`
    width:70vw;
    margin: 0 auto;
    h1 {
        font-weight: 700;
        font-family: 'Playfair Display', serif;
        font-style: italic;
        font-size: 5.5vw;
        color: #fff;
        margin-bottom: 1em;
        @media(max-width: 900px) {
            font-size: 3.5em;
        }
        @media(max-width: 500px) {
            font-size: 3em;
        }
    }
    @media(max-width: 900px) {
        width: 90%;
        margin: 0 0 0 2em; 
    }
`;

const ItemPage03 = styled(motion.div)`
    display: grid;
    grid-auto-flow: column;
    padding: 0 0 10em 0;
    @media(max-width: 700px) {
        grid-auto-flow: row;
        padding-bottom: 20%;
    }
    h2 {
        line-height: 1em;
        font-weight: 700i;
        font-style: italic;
        font-family: 'Playfair Display', serif;
        font-size: 5.5vw;
        @media(max-width: 500px) {
            font-size: 2em;
        }
    }
    span {
        font-family: 'Cormorant Garamond', serif;
        text-align: right;
        padding-top: 2em;
        font-size: 1em;
        width: 80%;
        justify-self: end;
        @media(max-width: 900px) {
            width: 100%;
        }
    }
    p {
        padding-left: 1.5em;
    }
    &:nth-child(2n+1) {
       color: #fff;
   }
`;

// const AsideMenu2 = styled(MenuLateral)`
//   border-right: solid 1px #000;
//   a {
//     color: #000;
//         &:hover {
//             color: #01a0c6;
//         }
//   }
// `


const tituloAnimate = {
    hidden: {
        opacity: 0,
        y: -100
    },
    show: {
        opacity: 1,
        y: 0
    }
}

const whatWeDoAnimateTitle = {
    hidden: {
        opacity: 0,
        x: -50
    },
    show: {
        opacity: 1,
        x: 0
    }
}

const whatWeDoAnimateText = {
    hidden: {
        opacity: 0,
        x: 50
    },
    show: {
        opacity: 1,
        x: 0
    }
}


export default function WhatWeDo() {
    return(
        <>
        <AsideMenu3 />
        <WhatWeDo01>
        <Container>
          <ContentPage03>

            <motion.h1 variants={tituloAnimate} initial="hidden" animate="show" >What We Do</motion.h1>

            <ItemPage03>
                <motion.h2 variants={whatWeDoAnimateTitle} initial="hidden" animate="show" transition={{duration: 0.5, delay: 0.2}}>Digital <p>Technology</p></motion.h2>
                <motion.span variants={whatWeDoAnimateText} initial="hidden" animate="show" transition={{duration: 0.5, delay: 0.5}}>Our team of developers offer a wide range of skills and services including frontend and backend web development, mobile applications, custom content management tools, installations and kiosks.<br/><br/>
                The systems we develop can be fitted to large or small sized projects and structures. So whatever format a project requires, we can custom develop it.</motion.span>
            </ItemPage03>

            <ItemPage03>
                <motion.h2 variants={whatWeDoAnimateTitle} initial="hidden" animate="show" transition={{duration: 0.5, delay: 0.8}}>Social Media <p>Marketing</p></motion.h2>
                <motion.span variants={whatWeDoAnimateText} initial="hidden" animate="show" transition={{duration: 0.5, delay: 1}}>Social Media has the power to make your consumers brand champions. At Sense8 we offer a range of services from Account Management to Growth Strategies and Content Creation, all produced to reinforce the relationship, elevate the consumer response, and increase brand awareness and conversion to your site.</motion.span>
            </ItemPage03>

            <ItemPage03>
                <motion.h2 variants={whatWeDoAnimateTitle} initial="hidden" animate="show" transition={{duration: 0.5, delay: 1.3}}>Website <p>Devlopment</p></motion.h2>
                <motion.span variants={whatWeDoAnimateText} initial="hidden" animate="show" transition={{duration: 0.5, delay: 1.6}}>From conceptualization, building, delivery and maintenance for web services and applications – at Sense8 we offer web services during all stages of existence. Not only have we built sites from the ground up, our Programming and Technical teams are digital born, and can create dynamic, interactive platforms that are optimized for SEO, UX, and UI – all in an innovative package of High Performance, Mobile friendly and Creative Design. Your website is often your potential client’s first impression of your business, make it count. And we’ll be there along the way, helping you to build, manage, and maintain.</motion.span>
            </ItemPage03>

            <ItemPage03>
                <motion.h2 variants={whatWeDoAnimateTitle} initial="hidden" animate="show" transition={{duration: 0.5, delay: 1.9}}>Digital <p>Banner Ads</p></motion.h2>
                <motion.span variants={whatWeDoAnimateText} initial="hidden" animate="show" transition={{duration: 0.5, delay: 2.2}}>We produce all formats of digital banners and media display ads using Google Web Designer, Adobe Animate and many others – from simple design banners to rich media fully elaborated motion.<br/><br/>
                For Google Ads, Google AdWords and any digital media vehicle.</motion.span>
            </ItemPage03>

            <ItemPage03>
                <motion.h2 variants={whatWeDoAnimateTitle} initial="hidden" animate="show" transition={{duration: 0.5, delay: 2.5}}>Mobile <p>App</p></motion.h2>
                <motion.span variants={whatWeDoAnimateText} initial="hidden" animate="show" transition={{duration: 0.5, delay: 2.8}}>From conceptualization to building, delivery, and maintenance for all platforms including Apple and Android apps – Sense8 has the smarter solution. Our Mobile Apps are created for a seamless user experience, and our brilliant team of Technology experts are continually exploring new and innovative applications to keep your platform on the cusp of the latest in technology. After initial design, our applications are put through a rigorous compatibility testing and certification to ensure it will surpass all standards immediately from activation.</motion.span>
            </ItemPage03>

            <ItemPage03>
                <motion.h2 variants={whatWeDoAnimateTitle} initial="hidden" animate="show" transition={{duration: 0.5, delay: 3.1}}>Landing <p>Page</p></motion.h2>
                <motion.span variants={whatWeDoAnimateText} initial="hidden" animate="show" transition={{duration: 0.5, delay: 3.4}}>We create and develops success standalone web pages, created specifically for the purposes of a marketing or advertising campaign.<br/>
                We help to plan the place where a visitor “lands” when they have clicked on a Google AdWords ad or similar.<br/>
                Landing Pages are designed with a single focused objective – known as a Call to Action (CTA).</motion.span>
            </ItemPage03>

            </ContentPage03>
            </Container>
          </WhatWeDo01>
        </>
    )
}