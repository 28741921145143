import AsideMenu2 from "./asideMenu2";
import styled from 'styled-components';
import { Container } from './styles.js';
import { motion } from 'framer-motion';


import isobar from '../assets/img/isobar.svg';
import tom from '../assets/img/tom-comunicacao.svg';
import jwt from '../assets/img/jwt.svg';
import mutato from '../assets/img/mutato.svg';
import kroton from '../assets/img/kroton.svg';
import porsche from '../assets/img/porsche.svg';
import pitagoras from '../assets/img/pitagoras.png';
import magnum from '../assets/img/magnum.svg';

const ClientList01 = styled.div`
    h1 {
        font-family: 'Playfair Display', serif;
        font-weight: bold;
        font-style: italic;
        font-size: 6em;
        padding-bottom: 1em;
        @media(max-width: 500px) {
            font-size: 3.5em;
            margin-left: .2em;
            white-space: nowrap;
        }
    }
`;
const GridClientList = styled.div`
    display: grid;
    grid-auto-flow: row;
    width: 50%;
    margin: 0 auto;
    @media(max-width: 900px) {
        grid-auto-flow: row;
        width: 100%;
    }
`;
const LogoClientes = styled.ul`
    display: grid;
    grid-template-rows: repeat(2, 60px);
    grid-template-columns: repeat(4, 130px);
    grid-gap: 2em;
    margin-left: 10em;
    @media(max-width: 1400px) {
        margin-left: 5em;
    }
    @media(max-width: 1200px) {
        margin-left: 0;
    }
    @media(max-width: 900px) {
        grid-template-rows: repeat(4, 50px);
		grid-template-columns: repeat(2, 120px);
		margin: 0 auto;
		grid-gap: 3em;
    }
    li {
        align-self: center;
    }
`;
const ListaClientes = styled.div`
    display: grid;
	grid-auto-flow: column;
    font-family: 'Cormorant Garamond', serif;
    color: #444;
    text-align: right;
    margin: 4em 0 0 0;
    /* width: 40%; */
    @media(max-width: 900px) {
		margin-left: .5em;
		margin-top: 4em;
		padding-right: 2em;
        width: 100%;
    }
    @media(max-width: 500px) {
        padding-right: 0;
        margin-right: 0;
    }
`;
const HaveBeenHere = styled.ul`
    margin: 0 0 0 0;
    color: #999999;
    padding-bottom: 10em;
    @media(max-width: 900px) {
        margin-left: .5em;
    }
`;

const LogoClientesAnimate = {
    hidden: {
        opacity: 0,
        scale: 0
    },
    show: {
        opacity: 1,
        scale: 1,
        transition: {
            duration: 0.5
        }
    }
}
const ListaClientesAnimate = {
    hidden: {
        opacity: 0,
        y: 400
    },
    show: {
        opacity: 1,
        y: 0,
        transition: {
            delay: 0.5,
            duration: 0.5
        }
    }
}

export default function ClientList() {
    return(
        <>
        <AsideMenu2 />
        <ClientList01>
            <Container>
                <h1>Client List</h1>

                <GridClientList>

                <LogoClientes>

                    <motion.li variants={LogoClientesAnimate} initial="hidden" animate="show"><a href="http://www.isobar.com.br" target="_blank"><img src={isobar} alt="isobar" /></a></motion.li>
                    <motion.li variants={LogoClientesAnimate} initial="hidden" animate="show"><a href="http://www.tomcomunicacao.com.br" target="_blank"><img src={tom} alt="tom comunicação" /></a></motion.li>
                    <motion.li variants={LogoClientesAnimate} initial="hidden" animate="show"><a href="http://www.jwt.com.br" target="_blank"><img src={jwt} alt="jwt" /></a></motion.li>
                    <motion.li variants={LogoClientesAnimate} initial="hidden" animate="show"><a href="http://www.muta.to" target="_blank"><img src={mutato} alt="mutato" /></a></motion.li>

                    <motion.li variants={LogoClientesAnimate} initial="hidden" animate="show"><a href="http://www.kroton.com.br" target="_blank"><img src={kroton} alt="kroton" /></a></motion.li>
                    <motion.li variants={LogoClientesAnimate} initial="hidden" animate="show"><a href="http://www.porschecenterbh.com.br" target="_blank"><img src={porsche} alt="porsche" /></a></motion.li>
                    <motion.li variants={LogoClientesAnimate} initial="hidden" animate="show"><a href="http://www.redepitagoras.com.br" target="_blank"><img src={pitagoras} alt="pitagoras" /></a></motion.li>
                    <motion.li variants={LogoClientesAnimate} initial="hidden" animate="show"><a href="http://www.magnum.com.br" target="_blank"><img src={magnum} alt="magnum" /></a></motion.li>

                </LogoClientes>

                <ListaClientes as={motion.div} variants={ListaClientesAnimate} initial="hidden" animate="show">
                    <ul>
                        <li>BDMG</li>
                        <li>FIA Online</li>
                        <li>UOL EdTech</li>
                        <li>Governo de Minas</li>
                        <li>Museu da Moto de Tiradentes</li>
                        <li>FIDENS Engenharia</li>
                        <li>Triumph BH Motorcycles</li>
                        <li>Razor Bros Barbershop</li>
                        <li>GuiaMKT</li>
                        <li>Festival de Vinho e Jazz de Tiradentes</li>
                        <li>Grupo BAMAQ</li>
                        <li>Colégio Pitágoras</li>
                        <li>Grupo Bel</li>
                        <li>BEL LAR Casa Contemporânea</li>
                        <li>Governo de Minas Gerais</li>
                        <li>Minas Film Commission</li>
                        <li>Grupo Anima</li>
                        <li>Nutrahair</li>
                        <li>Belfar Indústria Farmacêutica</li>
                        <li>Label Saúde</li>
                        <li>Araras Coffee & More (NY)</li>
                        <li>Solution Painting (NY)</li>
                        <li>Yuca Bar & Restaurant (NY)</li>
                        <li>Multilógica</li>
                        <li>Nova Era Silicon</li>
                        <li>GP Cargo</li>
                        <li>Croquis</li>
                        <li>Panda Promoções e Eventos</li>
                        <li>Link Comunicação</li>
                        <li>Fernando Botelho Advogados</li>
                        <li>Mediar FB</li>
                        <li>Santa Rosa Bureau Cultural</li>
                    </ul>

                    <HaveBeenHere>
                        <li>Have been here...</li>
                        <li>RCE Educação</li>
                        <li>Recepedia.com</li>
                        <li>Cubo.cc</li>
                        <li>Samba Hotéis</li>
                        <li>Patio Savassi</li>
                        <li>Vale</li>
                        <li>Igarapé</li>
                        <li>Tratenge/CSS</li>
                        <li>Shopping Itaquera</li>
                        <li>Wanmix</li>
                        <li>Florista Viajante</li>
                        <li>AMDA</li>
                        <li>Passaporte Verde</li>
                        <li>Marangoni do Brasil</li>
                        <li>Brasil Telecomunicações</li>
                        <li>Albano's</li>
                        <li>My Box - Self Storage</li>
                        <li>Fiat Automóveis</li>
                        <li>ETEP Faculdades</li>
                        <li>Colégio BILAC</li>
                        <li>Mundial Vistos</li>
                        <li>Construtora Terrazzas</li>
                        <li>Cultura Inglesa</li>
                        <li>Tambasa Atacadistas</li>
                        <li>Futurarte</li>
                        <li>Santa Casa Belo Horizonte</li>
                        <li>Hardy Design</li>
                        <li>Lápis Raro</li>
                        <li>Cultura Inglesa</li>
                        <li>Fundação Municipal de Cultura</li>
                        <li>Labtest</li>
                        <li>Transpes</li>
                        <li>naSala</li>
                        <li>Hoper Group</li>
                        <li>PNUMA</li>
                        <li>Rio 2016</li>
                        <li>Universo Produções</li>
                        <li>Mostra de Cinema de Tiradentes</li>
                        <li>Pif Paf</li>
                        <li>Sucos Tial</li>
                    </HaveBeenHere>
                </ListaClientes>

                </GridClientList>
            </Container>
        </ClientList01>
        </>
    )
}