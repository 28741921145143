import logo from './logo.svg';
import './App.css';
import { Switch, Route, HashRouter, Redirect } from 'react-router-dom';

import Home from './inc/home';
import Expertise from './inc/expertise';
import WhatWeDo from './inc/whatWeDo';
import WebSites from './inc/webSites';
import Banners from './inc/banners';
import SocialMedia from './inc/socialMedia';
import ClientList from './inc/clientList';
import Contact from './inc/contact';

// import AsideMenu from './inc/asideMenu';
// import AsideMenu2 from './inc/asideMenu2';
// import AsideMenu3 from './inc/asideMenu3';

function App() {
  return (
    <div className="App">
    <HashRouter>
        <Switch>
        <Route path="/" exact={true} component={Home} />
          <Route path="/home" exact={true} component={Home} />
          <Route path="/expertise" exact={true} component={Expertise} />
          <Route path="/whatWeDo" exact={true} component={WhatWeDo} />
          <Route path="/webSites" exact={true} component={WebSites} />
          <Route path="/banners" exact={true} component={Banners} />
          <Route path="/socialMedia" exact={true} component={SocialMedia} />
          <Route path="/clientList" exact={true} component={ClientList} />
          <Route path="/contact" exact={true} component={Contact} />
          {/* <Redirect to="/home" /> */}
        </Switch>
    </HashRouter>
    </div>
  );
}

export default App;
