import styled from 'styled-components';
import { motion } from 'framer-motion';
import { BrowserRouter, Link } from 'react-router-dom';

import logo01 from '../assets/img/logo-sense8.svg';

const Home01 = styled.div`
  background: #01a0c6;
`;
const ConteudoHome01 = styled.div`
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
  @media(max-width: 900px){
    grid-auto-flow: row;
  }
`;
const ContainerHome = styled.div`
  width: 70vw;
  height: 100vh;
  margin: 0 auto;
  padding-top: 3em;
  @media(max-width: 900px){
    width: 90vw;
  }
`;
const HeaderHome = styled.div`
  justify-self: start;
  padding-top: 2em;
  @media(max-width: 900px){
    width: 90vw;
    justify-self: center;
  }
  img {
    width: 280px;
    display: table;
    margin: 0 auto 1em auto;
  }
  p {
    text-align: center;
    width: 370px;
    font-size: 1.1em;
    font-weight: 300; 
    font-family: 'Cormorant Garamond', serif;
    @media(max-width: 900px){
      margin: 1em auto;
      width: 100%;
      font-size: 1em;
    }
  }
`;
const MenuHome = styled.div`
  li {
    font-family: 'Playfair Display', serif;
    font-weight: 700;
    font-style: italic;
    display:block;
    &:nth-child(1):hover a::after{
        opacity: 1;
        width: 30%;
        margin-left: -25%;
        @media(max-width: 700px){
            opacity: 1;
            width: 30vw;
            margin-left: -30%;
        }
    }
    &:nth-child(2):hover a::after{
        opacity: 1;
        width: 35%;
        margin-left: -30%;
        @media(max-width: 700px){
            opacity: 1;
            width: 35vw;
            margin-left: -35%;
        }
    }
    &:nth-child(3):hover a::after{
        opacity: 1;
        width: 22%;
        margin-left: -17%;
        @media(max-width: 700px){
            opacity: 1;
            width: 20vw;
            margin-left: -20%;
        }
    }
    &:nth-child(4):hover a::after{
        opacity: 1;
        width: 30.5%;
        margin-left: -25.5%;
        @media(max-width: 700px){
            opacity: 1;
            width: 30vw;
            margin-left: -30%;
        }
    }
    &:nth-child(5):hover a::after{
        opacity: 1;
        width: 26%;
        margin-left: -21%;
        @media(max-width: 700px){
            opacity: 1;
            width: 25vw;
            margin-left: -25%;
        }
    }
    @media(max-width: 900px){
      text-align: center;
    }
  }
  a {
    font-size: 5.5vw;
    color: #000;
    font-weight: 700;
    font-style: italic;
    ::after {
      content: "";
      position: absolute;
      height: 15px;
      width: 0;
      opacity: 0;
      background: #000;
      margin: 4% 0 0 5%;
      transition: all .4s;
      @media(max-width: 1200px){
        height: 12px;
        margin-top: 3.7%;
      }
      @media(max-width: 700px){
        height: 9px;
        margin-top: 5%;
        display: none;
      }
      /* @media(max-width: 500px){
        height: 9px;
        margin-top: 7%;
      } */
    }
    
    @media(max-width: 900px){
      font-size: 3em;
    }
  }
`;
const homeAnimate = {
  hidden: {opacity: 0},
  show: {opacity: 1}
}

const MenuHome01 = {
  hide: { x: 100 },
  show: { x: 0, transition: { delay: 0.5, when: 'beforeChildren' }}
};

export default function Home() {
  return (
  <>
    <Home01>
      <ContainerHome>
        <ConteudoHome01>
          <HeaderHome>
            <motion.img variants={homeAnimate} initial="hidden" animate="show" transition={{delay: 0.5, duration: 0.8}} src={logo01} />
            <motion.p variants={homeAnimate} initial="hidden" animate="show" transition={{delay: 1, duration: 0.8}} >SENSE8 DESIGNS AND DEVELOPS DIGITAL PRODUCTS FOR A DIGITAL WORLD.</motion.p>
          </HeaderHome>
          <MenuHome>
            <ul>
              <motion.li initial={{opacity: 0, x: 100}} animate={{opacity: 1, x: 0}} transition={{delay: 1.5}}><Link to="/expertise">Expertise</Link></motion.li>
              <motion.li initial={{opacity: 0, x: 100}} animate={{opacity: 1, x: 0}} transition={{delay: 1.6}}><Link to="/whatWeDo">What we do</Link></motion.li>
              <motion.li initial={{opacity: 0, x: 100}} animate={{opacity: 1, x: 0}} transition={{delay: 1.7}}><Link to="/webSites">Works</Link></motion.li>
              <motion.li initial={{opacity: 0, x: 100}} animate={{opacity: 1, x: 0}} transition={{delay: 1.8}}><Link to="/clientList">Client list</Link></motion.li>
              <motion.li initial={{opacity: 0, x: 100}} animate={{opacity: 1, x: 0}} transition={{delay: 1.9}}><Link to="/contact">Contact</Link></motion.li>
            </ul>
          </MenuHome>
        </ConteudoHome01>
      </ContainerHome>
    </Home01>
  </>
  )
}
