import { BrowserRouter, Link } from 'react-router-dom';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { HashLink } from 'react-router-hash-link';

import logoAside01 from '../assets/img/logo-sense8-icone-azul.svg';
import rightArrow01 from '../assets/img/right-arrow-blue.svg';

const MenuLateral = styled.div`
    position: fixed;
    width: 10em;
    height: 100vh;
    padding: 6em 1em 0 1em;
    border-right: solid 1px rgba(1, 160, 198, 0.3);
    z-index: 100;
    @media(max-width: 1000px) {
        margin-left: -8.6em;
		width: 10em;
		font-size: 1em;
        background: #000;
        transition: margin .3s;
        :hover {
            margin-left: 0;
        }
    }
    img {
        display: table;
        margin: 0 auto;
        width: 50px;
        cursor: pointer;
    }
    ul {
        display: table;
        list-style: none;
        margin: 6em auto 0 auto;
        padding: 0;
    }
    li {
        margin: .2em 0;
    }
    a {
        text-decoration: none;
        color: #4d4d4d;
        transition: all .5s;
        white-space: nowrap;
        font-family: 'Arial';
        &:hover {
            color: #01a0c6;
        }
        @media(max-width: 1000px) {
            font-size: 1em;
        }
    }
`;
const RightArrow = styled.div`
    display: none;
    opacity: 1;
	margin: 25% 0 0 7.7em;
	color: #fff;
	position: absolute;
    cursor: normal;
    @media(max-width: 1000px){
        display: block;
    }
    img {
        width: 1em;
    }
`;

export default function AsideMenu() {
    return(
        <div> 
            <MenuLateral>
                <Link to="/"><motion.img whileHover={{ scale: 1.1 }} src={logoAside01} alt="Logo Sense8" /></Link>
                <ul>
                    <li><Link to="/expertise">Expertise</Link></li>
                    <li><Link to="/whatWeDo">What we do</Link></li>
                    <li><Link to="/webSites">Works</Link></li>
                    <li><Link to="/clientList">Client list</Link></li>
                    <li><Link to="/contact">Contact</Link></li>
                </ul>
                <RightArrow><img src={rightArrow01} /></RightArrow>
            </MenuLateral>
        </div>
    )
}
