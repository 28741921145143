import AsideMenu from './asideMenu';
import { Container } from './styles.js';
import styled from 'styled-components';
import { motion } from 'framer-motion';

const Expertise01 = styled.div`
    background: #000;
    color: #01a0c6;
`
const ContainerExpertise01 = styled.div`
    display: grid;
    grid-auto-flow: row;
    gap: 2em;
    justify-items: end;
    margin-right: 10em;
    @media(max-width: 900px){
        margin-right: 0;
    }
    h1 {
        display: flex;
        font-size: 10em;
        margin: 0;
        @media(max-width: 900px){
            font-size: 5em;
        }
        @media(max-width: 500px){
            font-size: 3.5em;
        }
    }
    a {
        color: #01a0c6;
        transition: all .5s;
        &:hover {
            color: #4d4d4d;
        }
    }
`
const ExpertiseTxt = styled.div`
    width: 70%;
    @media(max-width: 900px){
        width: 100%;
    }
    p {
        margin: 2em 0;
        text-align: right;
        display: block;
        font-family: 'Cormorant Garamond', serif;
        font-size: 1.1em;
        font-weight: 100;
        @media(max-width: 900px){
            font-size: 1em;
            margin-left: 1em;
        }
    }
`


export default function Expertise() {
    return(
        <>
        <AsideMenu />
        <Expertise01>
            <Container>
                <ContainerExpertise01>
                <h1>
                    <motion.div initial={{opacity: 0, y: -30}} animate={{opacity:1, y: 0}} transition={{}}>E</motion.div>
                    <motion.div initial={{opacity: 0, y: -30}} animate={{opacity:1, y: 0}} transition={{delay: 0.1}}>x</motion.div>
                    <motion.div initial={{opacity: 0, y: -30}} animate={{opacity:1, y: 0}} transition={{delay: 0.2}}>p</motion.div>
                    <motion.div initial={{opacity: 0, y: -30}} animate={{opacity:1, y: 0}} transition={{delay: 0.3}}>e</motion.div>
                    <motion.div initial={{opacity: 0, y: -30}} animate={{opacity:1, y: 0}} transition={{delay: 0.4}}>r</motion.div>
                    <motion.div initial={{opacity: 0, y: -30}} animate={{opacity:1, y: 0}} transition={{delay: 0.5}}>t</motion.div>
                    <motion.div initial={{opacity: 0, y: -30}} animate={{opacity:1, y: 0}} transition={{delay: 0.6}}>i</motion.div>
                    <motion.div initial={{opacity: 0, y: -30}} animate={{opacity:1, y: 0}} transition={{delay: 0.7}}>s</motion.div>
                    <motion.div initial={{opacity: 0, y: -30}} animate={{opacity:1, y: 0}} transition={{delay: 0.8}}>e</motion.div>
                </h1>

                <ExpertiseTxt>
                    <motion.p initial={{opacity: 0, y: 100}} animate={{opacity: 1, y: 0}} transition={{duration: 0.8, delay: 1}}>Founded in 2004, Sense8 is headquartered in Belo Horizonte - Brazil - working globally.</motion.p>

                    <motion.p initial={{opacity: 0, y: 100}} animate={{opacity: 1, y: 0}} transition={{duration: 0.8, delay: 1.2}}>We are designers, producers and technologists who share a passion for creating great ideas and translating them into engaging, intelligent and innovative digital projects.<br/>
                    Our team lives and breathe design and technology, it shows on every project we work on.</motion.p>

                    <motion.p initial={{opacity: 0, y: 100}} animate={{opacity: 1, y: 0}} transition={{duration: 0.8, delay: 1.4}}>We are a multiplatform production company, creating websites, online ads, viral campaigns, social media, digital marketing, digital applications and interactive installations. We also create iOS and Android applications, content management tools and just about everything else that takes advantage of digital technology.</motion.p>
                </ExpertiseTxt>

                <motion.a initial={{opacity: 0, y: 100}} animate={{opacity: 1, y: 0}} transition={{duration: 0.5, delay: 1.8}}  href="http://www.sense8.com.br/PresentationSense82020.pdf" target="_blank"><b>Download our Presentation</b></motion.a>
                </ContainerExpertise01>
            </Container>
        </Expertise01>
        </>
    )    
}