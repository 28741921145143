import AsideMenu from "./asideMenu";
import styled from 'styled-components';
import { Container } from './styles.js';
import { motion } from 'framer-motion';

const Contact01 = styled.div`
    background: #000;
    color: #fff;
`;
const ContentContact01 = styled.div`
    h1 {
        font-family: 'Playfair Display', serif;
        font-weight: bold;
        font-style: italic;
        font-size: 6em;
        @media(max-width: 750px) {
            font-size: 3.5em;
            text-align: right;
            margin-right: .5em;
        }
    }
    a {
        color: #fff;
	    transition: all .3s;
        font-family: 'Cormorant Garamond', serif;
        :hover {
            color: #cecece;
        }
    }
`;
const GridContato = styled.div`
    display: grid;
	grid-template-columns: repeat(3, 28%);
	grid-template-rows: (2, 50%);
    margin-top: 10em;
	text-align: right;
	font-family: 'Cormorant Garamond', serif;
	font-weight: 200;
	font-size: .8em;
    @media(max-width: 750px) {
        grid-template-columns: 1fr;
		grid-template-rows: (3, 1fr);
		font-size: 1em;
		padding-right: 2em;
		grid-gap: 3em;
		margin-top: 2em;
    }
    span {
        color: #cecece;
    }
    b {
        font-weight: 500;
        font-size: 1.2em;
    }
`;
const ItemGridContato = styled.div`
    padding-right: 2em;
	border-right: solid 1px #666;
    @media(max-width: 750px) {
        border: none;
		padding: 0;
    }
`;
const Copyright = styled.div`
    margin: 4em 12.5vw 0 0;
	float: right;
	text-align: right;
	font-family: 'Cormorant Garamond', serif;
	font-size: .8em;
	color: #666;
    @media(max-width: 750px) {
        margin: 4em 2em 0 0;
        float: none;
    }
`;

const contactAnimate = {hidden:{opacity: 0}, show:{opacity: 1}}


export default function Contact() {
    return(
        <>
        <AsideMenu />
        <Contact01>
            <ContentContact01>
                <Container>
            
                    <motion.h1 variants={contactAnimate} initial="hidden" animate="show">Contact</motion.h1>

                    <GridContato>
                        <ItemGridContato as={motion.div} variants={contactAnimate} initial="hidden" animate="show" transition={{delay: 0.2}}>
                            <span>Online and Around</span><br/>
                            <b><a target="_blank" href="https://www.facebook.com/Sense8digital/">Facebook</a><br/>
                            <a target="_blank" href="https://twitter.com/Sense8digital">Twitter</a><br/>
                            <a target="_blank" href="https://instagram.com/Sense8digital">Instagram</a></b>
                        </ItemGridContato>
                        <ItemGridContato as={motion.div} variants={contactAnimate} initial="hidden" animate="show" transition={{delay: 0.4}}>
                            <span>Voice number</span><br/>
                            br <b>+55 31 3347-5881</b><br/>
                            us <b>+1 914 608 4299</b>
                            <br/><br/>
                            <span>WhatsApp</span><br/>
                            <b><a target="_blank" href="https://api.whatsapp.com/send?phone=5531988721000&text=Hi%20Sense8%20Digital">+55 31 98872-1000</a></b>
                            <br/><br/>
                            <span>Email us</span><br/>
                            <b><a href="mailto:contact@sense8.digital">contact@sense8.digital</a></b>
                        </ItemGridContato>
                        <ItemGridContato as={motion.div} variants={contactAnimate} initial="hidden" animate="show" transition={{delay: 0.8}}>
                            <span>Address</span><br/>
                            <b>Rua Major Lopes, 07 - 1º andar<br/>
                            Savassi<br/>
                            Belo Horizonte - Brazil<br/>
                            CEP.: 30330-050</b>
                        </ItemGridContato>
                    </GridContato>

                    <Copyright as={motion.div} variants={contactAnimate} initial="hidden" animate="show" transition={{delay: 1}}>
                    ⑧ Copyright Sense8 Interactive<br/>
                    2004-2021
                    </Copyright>
                </Container>
            </ContentContact01>
        </Contact01>
        </>
    )
}