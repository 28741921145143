import AsideMenu from "./asideMenu";
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { useState, useEffect } from "react";
import { BrowserRouter, Link } from 'react-router-dom';

import arrowWorks from "../assets/img/works/seta01.png";

const Works01 = styled.div `
	color: #fff;
	padding-bottom: 4em;
	background: #000 !important;
    min-height: 100vh;
`;
const ContentWorks = styled.div`
    margin: 0 0 0 20%;
	width: 80vw;
`;
const HeaderWorks = styled.div`
    display: grid;
	grid-template-columns: 49% 0.8% 49%;
	align-content: center;
	height: 300px;
    @media(max-width: 900px) {
        display: flex;
        flex-direction: column;
    }
    h1 {
        justify-self: start;
        font-style: italic;
        padding-top: .4em;
        font-size: 6em;
        @media(max-width: 650px) {
            font-size: 3.5em;
        }
    }
`;
const Separator = styled.div`
    border-right: solid 1px rgba(255, 255, 255, 0.3);
	position: relative;
	left: 50%;
	transform: translateX(-50%);
	height: 300px;
    @media(max-width: 1000px) {
        opacity: 0;
        height: 0;
    }
`;
const MenuWorks = styled.div`
    display: flex;
    justify-self: end;
	margin: 0 3em 0 0;
	padding-top: 4em;
    white-space: nonowrap;
    @media(max-width: 1200px) {
        margin: 0 0 0 0;
    }
    /* @media(max-width: 1000px) {
        display: flex;
        flex-direction: column;
    }*/
    @media(max-width: 650px) {
        flex-direction: row;
    } 
    @media(max-width: 450px) {
        margin-left: -2em;
    }
    a {
        color: #fff;
	    margin: 0 3em;
        white-space: nowrap;
        transition: color .2s;
        font-family: 'berthold';
        font-weight: 200;
        white-space: nonowrap;
        @media(max-width: 1400px) {
            margin: 0 2em;
        }
        @media(max-width: 650px) {
            margin: 0 1em;
        }
        ::before {
            content: "";
            position: absolute;
            margin: .35em 0 0 -2.4em;
            width: 30px;
            height: 1px;
            background-color: #00aeef;
            -webkit-transition: all 0.3s ease;
            -o-transition: all 0.3s ease;
            transition: all 0.3s ease;
            @media(max-width: 650px) {
                margin: .55em 0 0 -1.2em;
                width: 15px;
            }
        }
        :hover {
            color: #00aeef;
            ::before {
                background-color: #fff;
            }
        }
    }
`;
const GridWorks = styled.div`
    display: grid;
	grid-template-columns: 50% 50%;
	grid-gap: 0;
	background: #000;
	max-width: 100%;
	min-width: 0;
    @media(max-width: 500px) {
        grid-template-columns: 1fr;
    }
`;
const ItemWorks = styled.div`
    /* margin-top: -0.5em; */
`;
const ImgItemWorks = styled.div`
    margin-top: -4px;
    width: 100%;
    img {
        object-fit: cover;
        object-position: center center;
        transition: all .2s;
        width: 100%;
        height: 100%;
        @media(max-width: 500px) {
            filter: brightness(50%);
        }
    }
    :hover {
        filter: brightness(50%);
        transition: all .2s;
    }
`;
const LegendaWorks = styled.div`
    display: none;
    grid-template-rows: 2em 2em;
    gap: 0;
    width: 300px;
	height: auto;
    position: absolute;
    @media(max-width: 500px) {
        display: none;
    }
    h3 {
        img {
            position: absolute;
            bottom: 8em;
            margin-left:2em;
            width: auto;
            object-fit: contain;
        }
    }
    i {
        position: absolute;
        bottom: 6em;
        color: #fff;
        object-fit: contain;
        width: 180px;
    }
`;

const LegendaWorksMobile = styled.div`
    display: none;
    grid-template-rows: 2em 2em;
    gap: 0;
    width: 300px;
	height: auto;
    position: absolute;
    @media(max-width: 500px) {
        display: grid;
    }
    h3 {
        img {
            position: absolute;
            bottom: 6em;
            margin-left:2em;
            width: 50%;
            object-fit: contain;
        }
    }
    i {
        position: absolute;
        bottom: 5em;
        left: 2em;
        color: #fff;
        object-fit: contain;
        width: 120px;
        img {
            width: 100%;
        }
    }
`

const titleAnimate = {
    hidden: {
        opacity: 0,
        y: -50
    },
    show: {
        opacity: 1,
        y: 0,
        transition: {
            delay: 0.5
        }
    }
}
const borderAnimate = {
    hidden: { opacity: 0, y: 300 },
    show: { opacity: 1, y: 0, transition: {delay: 2.2, duration: 0.8} }
}
const menuAnimate = {
    hidden: { opacity: 0, scale: 0 },
    show: { opacity: 1, scale: 1 }
}
const itensAnimate = {
    hidden: { opacity: 0, x: -100 },
    show: { opacity: 1, x: 0 }
}

const showDiv = {
    hidden: { display: 'none'},
    show: { display: 'grid'}
}   
const animateLegendImg = {
    hidden: { display: 'none', opacity: 0, y: -50 },
    show: { display: 'block', opacity: 1, y: -1,  
    transition: { duration: 0.5 } } }            

const animateLegendArrow = {
    hidden: { display: 'none', opacity: 0, marginLeft: '0' },
    show: { display: 'block', opacity: 1, marginLeft: '3em',
    transition: { duration: 0.5 } } }

// export const getStaticProps = async () => {
//     const res = await fetch('http://sense8.digital/admin/wp-json/wp/v2/portfolio_web_sites');
//     const data = await res.json();
//     return {
//         props: { itemsWebSites: data }
//     }
// } 

const Works = ({ itemsWebSites }) => {
    const [animateHover, setAnimateHover] = useState(false);

    const [posts, setPosts] = useState([]);
    useEffect(() => {
        async function loadPosts() {
            const response = await fetch('http://sense8.digital/admin/wp-json/wp/v2/portfolio_web_sites/?per_page=100');
            if(!response.ok) {
                return;
            }
            const posts = await response.json();
            setPosts(posts);
        }
        loadPosts();
   }, [])

    return(
        <>
        <AsideMenu />
        <Works01>
            <ContentWorks>
                <HeaderWorks>
                    <motion.h1 variants={titleAnimate} initial="hidden" animate="show">Works</motion.h1>
                    <Separator as={motion.div} variants={borderAnimate} initial="hidden" animate="show"></Separator>
                    <MenuWorks>
                        <Link as={motion.a} variants={menuAnimate} initial="hidden" animate="show" href="#" transition={{delay: 0.8}} to="/webSites">Websites</Link>
                        <Link as={motion.a} variants={menuAnimate} initial="hidden" animate="show" href="#" transition={{delay: 1}} to="/socialMedia">Social Media</Link>
                        <Link as={motion.a} variants={menuAnimate} initial="hidden" animate="show" href="#" transition={{delay: 1.2}} to="/banners">Banners</Link>
                    </MenuWorks>
                </HeaderWorks>

                <GridWorks as={motion.div} variants={itensAnimate} initial="hidden" animate="show" href="#" transition={{delay: 1.4, duration: 0.8}}>

                {posts.map(item => (
                    <ItemWorks key={item.id} as={motion.div} whileHover="show" initial="hidden" animate="hidden">
                        <a target="_blank" href={item.acf.link_externo}>
                        <ImgItemWorks>
                            <img src={item.acf.imagem_de_fundo.url} />
                        </ImgItemWorks>
                        <LegendaWorks as={motion.div} variants={showDiv}>
                            <motion.h3 variants={animateLegendImg}><img src={item.acf.logotipo.url} /></motion.h3>
                            <motion.i variants={animateLegendArrow}><img src={arrowWorks} /></motion.i>
                        </LegendaWorks>
                        <LegendaWorksMobile>
                            <h3><img src={item.acf.logotipo.url} /></h3>
                            <i><img src={arrowWorks} /></i>
                        </LegendaWorksMobile>
                        </a>
                    </ItemWorks>
                ))}
               
                </GridWorks>
            </ContentWorks>
        </Works01>
        </>
    )
}

export default Works;